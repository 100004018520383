<style lang="postcss" scoped>
  .red-pack {
    & .note {
      background: var(--base-color);
      color: #fff;
      padding: 0.02rem 0.04rem;
      border-radius: 0.02rem;
      line-height: 1;
    }

    & .icon-enter {
      color: #999;
    }

    & .tit {
      padding: 0.08rem;
      text-align: center;
    }

    & .list {
      padding: 0.08rem;
      height: 4rem;
      overflow: scroll;
      margin-bottom: env(safe-area-inset-bottom);
      overscroll-behavior: contain;

      & .cell {
        margin-bottom: 0.08rem;
      }
    }

    & .icon-xuanze {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 99;
      font-size: 0.38rem;
      line-height: 1;
      color: var(--base-color);
    }
  }
</style>
<template>
  <div class="red-pack">
    <div class="flex-between" @click="showList = !showList">
      <span class="flag">使用红包</span>
      <div class="flex-right-center">
        <p class="note">满{{info.t_limit}}减{{info.t_price}}</p>
        <div class="iconfont icon-enter"></div>
      </div>
    </div>
    <my-model-bottom v-model="showList">
      <div class="tit">选择可使用的红包</div>
      <div class="list">
        <div @click="choose(item)" v-for="item in list" :key="item.t_t_id">
          <my-red-pack :item="item" class="cell">
            <span class="iconfont icon-xuanze" v-if="item.t_t_id === info.t_t_id"></span>
          </my-red-pack>
        </div>
      </div>
    </my-model-bottom>
  </div>
</template>
<script>
  import ModelBottom from './ModelBottom'
  import RedPack from './RedPack'

  export default {
    name: 'MyRedPackModel',
    components: {
      [ModelBottom.name]: ModelBottom,
      [RedPack.name]: RedPack
    },
    model: {
      prop: 'info'
    },
    props: {
      list: {
        type: Array,
        required: true
      },
      info: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        showList: false
      }
    },
    methods: {
      choose (item) {
        this.$emit('input', item)
        setTimeout(() => {
          this.showList = false
        }, 300)
      }
    }
  }
</script>
